<template>
  <v-container fluid>
    <v-row>
      <v-col class="d-flex flex-row align-center">
        <v-btn text @click="()=>{this.$router.go(-1)}"><v-icon dark>mdi-arrow-left</v-icon></v-btn>
        <h1>{{plural}}</h1>
        <v-btn class="ml-2" small fab color="info" @click="newForm"><v-icon>mdi-plus</v-icon></v-btn>
        <v-progress-circular
            indeterminate
            color="green"
            v-if="dataLoader"
            style="margin-left: 10px;"
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-file-input
          accept="image/*"
          name="file"
          show-size
          counter
          label="Global Logo"
          :rules="imageRules"
          @change="onAddImg"
        ></v-file-input>
        <v-btn :disabled="!imgFile || imgFile.size >= 1024000 " color="info" @click="uploadLogoImage()">Upload</v-btn>
      </v-col>
      <v-col>
        <img :src="this.getEndpoint+'/images/logo.png'" width="150px;" alt="Logo">
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <span class="d-flex flex-row">
          <h3 v-if="isCreate">Create {{singular}}</h3>
          <h3 v-else>Edit Key:{{form.key}}</h3>
        </span>
        <span v-if="!isCreate" class="d-flex flex-row mb-2">
          <v-btn v-if="!isEditable" @click="isEditable=!isEditable">Edit</v-btn>
          <v-btn v-else @click="cancelForm">Cancel</v-btn>
        </span>
        <v-form :disabled="!isEditable && !isCreate" v-model="validForm">
          <span class="d-flex flex-column">
            <v-text-field :rules="requiredRules" outlined label="Key" v-model="form.key"/>
            <v-textarea outlined label="Value" v-model="form.value"/>
          </span>
        </v-form>
        <span v-if="isEditable && !isCreate" class="d-flex flex-row">
          <v-btn :disabled="!validForm" class="ml-1" color="success" @click="updateData" :loading="formLoader">Update</v-btn>
          <v-btn :disabled="!validForm" class="ml-1" color="danger" @click="deleteGlobal" :loading="formLoader">Delete</v-btn>
        </span>
        <span v-if="isCreate" class="d-flex flex-row">
          <v-btn :disabled="!validForm" class="ml-1" color="success" @click="createData" :loading="formLoader">Save</v-btn>
        </span>
      </v-col>
      <v-col cols="8">
        <v-data-table
            :items="data"
            :headers="headers"
            :items-per-page="-1"
            @click:row="rowClick"
            :loading="dataLoader"
        >
        </v-data-table>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import axios from "axios";

export default {
  name: 'Home',
  data () {
    return {
      dataLoader: false,
      formLoader: false,
      imageRules: [
        v => !v || v.size < 1024000 || 'Logo size should be less than 1 MB!',
      ],
      imgFile: '',
      snackObj: {
        state: false,
        color: '',
        text: ''
      },

      isEditable: false,
      isCreate: true,

      singular: "Global Variable",
      singularLower: "global",
      plural: "Global Variables",
      pluralLower: "globals",

      requiredRules: [
        v => !!v || 'Field is required.'
      ],

      data: [],
      form: {},
      validForm: false,

      headers: [
        { text: 'Key', align: 'start', value: 'key' },
        { text: 'Value', value: 'value' }
      ]
    }
  },
  computed: {
    ...mapGetters(['getEndpoint'])
  },
  async mounted(){
    try { 
      this.dataLoader = true;

      await this.fetchData();

    } catch (error) {
      console.error(error)
      this.snack(error.msg || error, "error");
    }finally {
      this.dataLoader = false;
    }
  },
  methods: {
    newForm(){
      this.form = {
        key: '',
        value: ''
      }
      this.isEditable = true;
      this.isCreate = true;
    },
    rowClick(row){
      this.newForm();
      this.form.key = row.key
      this.form.value = row.value;

      this.isEditable = false;
      this.isCreate = false;
    },
    onAddImg(img){
      this.imgFile = img
    },
    async uploadLogoImage(){
        try{
          this.imgUploadLoader = true;
          if(!this.imgFile) throw "No Image Selected.";

          let formData = new FormData();
          formData.append("file", this.imgFile);
          formData.append("name", 'logo.png');

          let fileUpload = await axios.post(`${this.getEndpoint}/api/globals/uploadLogo`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          console.log(fileUpload);
        }
        catch(e) {
          console.error(e);
        }
        finally {
          this.imgUploadLoader = true;
        }
      },
    async cancelForm(){
      try{
        this.formLoader = true;

        await this.fetchData();

        this.form = this.data.find(x=>x.key===this.form.key);

        //custom form data changes here

        this.isEditable = false;
      }
      catch (e) {
        console.error(e)
        this.snack(e.msg || e, "error");
      }
      finally
      {
        this.formLoader = false;
      }
    },
    async fetchData(){
      try{
        this.dataLoader = true;

        let res = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}`)
        if(res.data.error) throw res.data.error
        this.data = res.data.data
      }
      catch (e) {
        console.error(e)
        this.snack(e.msg || e, "error");
      }
      finally {
        this.dataLoader = false;
      }
    },
    async createData(){
      try{
        this.formLoader = true;

        //custom form data changes here

        let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}`, this.form)
        if(res.data.error) throw res.data.error
        this.snack(`${this.singular} created!`, "success");
        this.newForm();

        await this.fetchData();
      }
      catch (e) {
        console.error(e)
        this.snack(e.msg || e, "error");
      }
      finally {
        this.formLoader = false;
      }
    },
    async updateData(){
      try{
        this.formLoader = true;

        //custom form data changes here

        let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/${this.form.key}`, this.form)
        if(res.data.error) throw res.data.error
        this.isEditable = false;
        //custom form data changes here

        this.snack(`${this.singular} updated!`, "success");
      }
      catch (e) {
        console.error(e)
        this.snack(e.msg || e, "error");
      }
      finally {
        this.formLoader = false;
      }
    },
    async deleteGlobal(){
      try{
        this.formLoader = true;
        let res = await axios.delete(`${this.getEndpoint}/api/${this.pluralLower}/${this.form.key}`)
        if(res.data.error) throw res.data.error
        this.newForm();
        this.snack(`${this.singular} Deleted!`, "success");
        await this.fetchData()
      }
      catch (e) {
        console.error(e)
        this.snack(e.msg || e, "error");
      }
      finally {
        this.formLoader = false;
        this.isEditable = true
      }
    },
    snack(text, color=""){
      this.snackObj.text = text;
      this.snackObj.state = true;
      this.snackObj.color = color;
    },
    formatDate(d, type="short"){
      if(!d) return "None";
      if(type=="short")
        return (new Date(d)).toLocaleDateString('en-GB')
      if(type=="long")
        return (new Date(d)).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
    }
  }
}
</script>
