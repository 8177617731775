<template>
  <v-container fluid>
    <v-row>
      <v-col class="d-flex flex-row align-center">
        <v-btn text @click="()=>{this.$router.go(-1)}"><v-icon dark>mdi-arrow-left</v-icon></v-btn>
        <h1>{{plural}}</h1>
        <v-btn class="ml-2" small fab color="info" @click="newForm"><v-icon>mdi-plus</v-icon></v-btn>
        <v-progress-circular
            indeterminate
            color="green"
            v-if="dataLoader"
            style="margin-left: 10px;"
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <span class="d-flex flex-row">
          <h3 v-if="isCreate">Create {{singular}}</h3>
          <h3 v-else>Edit {{singular}}:{{form.name}}</h3>
        </span>
        <span v-if="!isCreate" class="d-flex flex-row mb-2">
          <v-btn v-if="!isEditable" @click="isEditable=!isEditable">Edit</v-btn>
          <v-btn v-else @click="cancelForm">Cancel</v-btn>
        </span>
        <v-form :disabled="!isEditable && !isCreate" v-model="validForm">
          <span class="d-flex flex-column">
            <v-text-field :rules="requiredRules" outlined label="Name" v-model="form.name"/>
            <v-textarea outlined label="Metadata" v-model="form.metadata"/>
          </span>
        </v-form>
        <span v-if="isEditable && !isCreate" class="d-flex flex-row">
          <v-btn :disabled="!validForm" class="ml-1" color="success" @click="updateData" :loading="formLoader">Update</v-btn>
        </span>
        <span v-if="isCreate" class="d-flex flex-row">
          <v-btn :disabled="!validForm" class="ml-1" color="success" @click="createData" :loading="formLoader">Save</v-btn>
        </span>
      </v-col>
      <v-col cols="8">
        <v-data-table
            :items="data"
            :headers="headers"
            :items-per-page="-1"
            @click:row="rowClick"
            :loading="dataLoader"
        >
        </v-data-table>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import axios from "axios";

export default {
  name: 'Home',
  data () {
    return {
      dataLoader: false,
      formLoader: false,

      snackObj: {
        state: false,
        color: '',
        text: ''
      },

      isEditable: false,
      isCreate: true,

      singular: "Shipping Method",
      singularLower: "shippingmethod",
      plural: "Shipping Methods",
      pluralLower: "shippingmethods",

      requiredRules: [
        v => !!v || 'Field is required.'
      ],

      data: [],
      form: {},
      validForm: false,

      headers: [
        { text: 'Name', align: 'start', value: 'name' },
        { text: 'Metadata', value: 'metadata' },
      ]
    }
  },
  computed: {
    ...mapGetters(['getEndpoint'])
  },
  async mounted(){
    try { 
      this.dataLoader = true;

      await this.fetchData();

    } catch (error) {
      console.error(error)
      this.snack(error.msg || error, "error");
    }finally {
      this.dataLoader = false;
    }
  },
  methods: {
    newForm(){
      this.form = {
        id: null,
        name: '',
        metadata: ''
      }
      this.isEditable = true;
      this.isCreate = true;
    },
    rowClick(row){
      this.newForm();
      this.form.id = row.id;
      this.form.name = row.name;
      this.form.metadata = row.metadata;

      this.isEditable = false;
      this.isCreate = false;
    },
    async cancelForm(){
      try{
        this.formLoader = true;

        await this.fetchData();

        this.form = this.data.find(x=>x.id===this.form.id);

        //custom form data changes here

        this.isEditable = false;
      }
      catch (e) {
        console.error(e)
        this.snack(e.msg || e, "error");
      }
      finally
      {
        this.formLoader = false;
      }
    },
    async fetchData(){
      try{
        this.dataLoader = true;

        let res = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}`)
        if(res.data.error) throw res.data.error
        this.data = res.data.data
      }
      catch (e) {
        console.error(e)
        this.snack(e.msg || e, "error");
      }
      finally {
        this.dataLoader = false;
      }
    },
    async createData(){
      try{
        this.formLoader = true;

        //custom form data changes here

        let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}`, this.form)
        if(res.data.error) throw res.data.error
        this.snack(`${this.singular} created!`, "success");
        this.newForm();

        await this.fetchData();
      }
      catch (e) {
        console.error(e)
        this.snack(e.msg || e, "error");
      }
      finally {
        this.formLoader = false;
      }
    },
    async updateData(){
      try{
        this.formLoader = true;

        //custom form data changes here

        let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/${this.form.id}`, this.form)
        if(res.data.error) throw res.data.error
        this.isEditable = false;
        //custom form data changes here

        this.snack(`${this.singular} updated!`, "success");
      }
      catch (e) {
        console.error(e)
        this.snack(e.msg || e, "error");
      }
      finally {
        this.formLoader = false;
      }
    },
    snack(text, color=""){
      this.snackObj.text = text;
      this.snackObj.state = true;
      this.snackObj.color = color;
    },
    formatDate(d, type="short"){
      if(!d) return "None";
      if(type=="short")
        return (new Date(d)).toLocaleDateString('en-GB')
      if(type=="long")
        return (new Date(d)).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
    }
  }
}
</script>
