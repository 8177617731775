<template>
  <v-container fluid>
    <v-row>
      <v-col class="d-flex flex-row align-center">
        <v-btn text @click="()=>{this.$router.go(-1)}"><v-icon dark>mdi-arrow-left</v-icon></v-btn>
        <h1>Admin Utilities</h1>
        <v-progress-circular
            indeterminate
            color="green"
            v-if="dataLoader"
            style="margin-left: 10px;"
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn @click="openRebootDialog">Reboot System</v-btn>
      </v-col>
    </v-row>
    <v-dialog
      v-model="rebootDialog.isOpen"
      width="600px"
    >
      <v-card>
        <v-card-title class="text-h5">Confirm Reboot System</v-card-title>
        <v-card-text>
          <v-text-field v-model="rebootDialog.password" label="Password" type="password" outlined></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" :disabled="!rebootDialog.password" text @click="initiateRebootSequence">Confirm Reboot</v-btn>
          <v-btn color="primary" text @click="closeRebootDialog">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import axios from "axios";

export default {
  name: 'Home',
  data () {
    return {
      dataLoader: false,
      formLoader: false,

      snackObj: {
        state: false,
        color: '',
        text: ''
      },

      isEditable: false,
      isCreate: true,

      requiredRules: [
        v => !!v || 'Field is required.'
      ],

      data: [],
      form: {},
      validForm: false,

      rebootDialog: {
        isOpen: false,
        loading: false,
        password: ''
      },

      headers: [
        { text: 'Key', align: 'start', value: 'key' },
        { text: 'Value', value: 'value' }
      ]
    }
  },
  computed: {
    ...mapGetters(['getEndpoint'])
  },
  async mounted(){
    try { 
      this.dataLoader = true;
      axios;
    } catch (error) {
      console.error(error)
      this.snack(error.msg || error, "error");
    }finally {
      this.dataLoader = false;
    }
  },
  methods: {
    
    snack(text, color=""){
      this.snackObj.text = text;
      this.snackObj.state = true;
      this.snackObj.color = color;
    },
    openRebootDialog(){
      this.rebootDialog.isOpen = true
    },
    closeRebootDialog(){
      this.rebootDialog = {
        isOpen: false,
        loading: false,
        password: ''
      }
    },
    async initiateRebootSequence(){
      try {
        let res = await axios.post(`${this.getEndpoint}/api/utilities/reboot`, {pin: this.rebootDialog.password})
        if(res.data.error) throw res.data.error
        this.snack("System Rebooting.")
        this.closeRebootDialog()
      } catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error");        
      }
    }
  }
}
</script>
