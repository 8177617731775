<template>
  <div class="" style="width: 100%;">
    <v-row>
      <v-col>
        <v-container fluid>
          <v-row>
            <v-col class="d-flex flex-row align-center">
              <v-btn text class="mx-2" to="/products">
                <v-icon dark>mdi-arrow-left</v-icon>
              </v-btn>
              <h1>{{this.plural}}</h1>
              <v-progress-circular
                  indeterminate
                  color="green"
                  v-if="loader"
                  style="margin-left: 10px;"
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                  :headers="headers"
                  :items="allData"
                  :items-per-page="-1"
                  class="elevation-1"
                  @click:row="rowClick"
              >
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col v-if="isAllowed('brand','c')" cols="4">
        <v-container>
          <v-row>
            <v-col class='d-flex flex-row align-center'>
              <h1>Add New {{this.singular}}</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  label="Name"
                  outlined
                  v-model="data.name"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn @click="create" :disabled="!this.data.name">Create</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>

    <v-dialog scrollable v-model="existingDialog" width="500">
      <v-card>
        <v-card-title class="text-h5">
          Confirm Create
        </v-card-title>
        <v-card-text style="padding-top: 20px;">
          <p>Similar {{this.plural}} already exist. Are you sure you want to create another?</p>
          <br>
          <h3>Similar {{this.plural}}</h3>
          <span v-for="(item, i) in existingData" :key="i">{{i!==0?" | ":""}}<b>{{item.name}}</b>(ID:{{item.id}})</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary" text @click="confirmCreate">
            Create
            <v-progress-circular
                indeterminate
                color="green"
                v-if="dialogLoading"
                style="margin-left: 10px;"
            ></v-progress-circular>
          </v-btn>
          <v-btn class="error" text @click="closeDialog">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>

</template>
<script>
  import axios from 'axios';
  import { mapGetters } from "vuex";
  export default {
    data () {
      return {
        deleteDialog: false,
        deleteConfirmed: true,
        loader: true,

        snackObj: {
          state: false,
          color: '',
          text: ''
        },

        max25chars: v => v.length <= 25 || 'Input too long!',
        headers: [
          {
            text: 'Name',
            align: 'start',
            sortable: true,
            value: 'name',
          }
        ],

        existingData: [],
        existingDialog: false,
        dialogLoading: false,

        data: {},
        allData: [],
        singular: "Brand",
        singularLower: "brand",
        plural: "Brands",
        pluralLower: "brands"
      }
    },
    async mounted(){
        await this.getAllData();
    },
    computed: {
      ...mapGetters(['getEndpoint', "isAllowed"])
    },
    methods: {
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      rowClick(row){
        this.$router.push({ path: `/${this.pluralLower}/view/${row.id}`})
      },
      async create(){
        try {
          let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}`, this.data)
          if(res.data.error) throw res.data.error

          if(res.data.data.created){
            this.snack(`${this.singular} Created 🎉`);
            this.data={};
            await this.getAllData();
          }
          else{
            this.existingDialog = true;
            this.existingData = res.data.data.data;
          }
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        }
      },
      async confirmCreate(){
        try {
          this.dialogLoading = true;
          let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}/confirmCreate`, this.data)
          if(res.data.error) throw res.data.error

          this.snack(`${this.singular} Created 🎉`, "success");
          this.data={};
          await this.getAllData();
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        }
        finally {
          this.closeDialog();
        }
      },
      closeDialog(){
        this.existingDialog = false;
        this.existingData = [];
        this.dialogLoading = false;
      },
      async getAllData(){
        try {
          let res = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}`)
          if(res.data.error) throw res.data.error

          this.allData = res.data.data
        } catch (error) {
          console.error(error);
          this.snack(error.msg || error, "error");
        } finally {
          this.loader = false;
        }
      }
    }
  }
</script>
