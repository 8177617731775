<template>
  <div style="width: 100%;">
    <v-container>
      <v-row>
        <v-col class="d-flex flex-row align-center">
          <v-btn @click="$router.go(-1)" text><v-icon>mdi-arrow-left</v-icon></v-btn>
          <h1>Supplier Inventory</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h3></h3>
          <span class="d-flex flex-row"></span>
        </v-col>
      </v-row>
      <v-row v-if="data && data.length===0">
        <v-col>
          <span class="red--text">No records found with the provided filters.</span>
        </v-col>
      </v-row>
      <v-row v-if="data">
        <v-col>
          <span><span class="text-h6">Showing all records</span></span>
        </v-col>
      </v-row>
      <v-row v-if="data && data.length>0">
        <v-col>
          <v-card outlined>
            <v-card-text>
              <v-row>
                <v-col><b>Supplier</b></v-col>
                <v-col><b>Product</b></v-col>
                <v-col><b>SKU</b></v-col>
                <v-col><b>Quantity</b></v-col>
              </v-row>
              <span v-for="(supplier, i) of data" :key="i">
                <v-row v-for="(product, j) of supplier.Products" :key="j">
                  <v-col style="margin-bottom: -10px;" v-if="j===0">
                    <p style="cursor: pointer;" @click="$router.push(`/suppliers/view/${supplier.id}`)"><span class="text-h6">{{supplier.name}}</span> (ID: {{supplier.id}})</p>
                  </v-col>
                  <v-col v-else></v-col>
                  <v-col><p @click="$router.push(`/products/view/${product.id}`)" style="cursor: pointer;">#{{product.id}} | {{product.name}}</p></v-col>
                  <v-col>{{product.sku}}</v-col>
                  <v-col></v-col>
                </v-row>
              </span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios';
  import {mapGetters} from "vuex";
  import utils from "../../plugins/helpers"
  export default {
    data () {
      return {
        utils: utils,
        deleteDialog: false,
        deleteConfirmed: true,
        loader: false,
        snackObj: {
          state: false,
          color: '',
          text: ''
        },

        filters: {
        },

        totals: null,
        data: null,
      }
    },
    async mounted(){
        await this.getAllData();
    },
    computed: {
      ...mapGetters(['getEndpoint', "isAllowed", 'lookupUsername']),
    },
    methods: {
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      async getAllData(){
        try {
          this.loader = true;

          await this.fetchReport();
        } catch (error) {
          console.error(error);
          this.snack(error.msg || error, "error");
        } finally {
          this.loader = false;
        }
      },
      async fetchReport(){
        try {
          this.loader = true;
          this.data = null;

          let res = await axios.post(`${this.getEndpoint}/api/reporting/supplierInventory`, this.filters)
          if(res.data.error) throw res.data.error
          if(res.data.data.length===0) throw "No records found within the provided filters."

          this.data = res.data.data;
          console.log(this.data)

          let rows = [];
          for(let supplier of this.data){
            for(let product of supplier.Products){
              rows.push({
                Supplier: `ID:${supplier.id} | ${supplier.name}`,
                Product: `ID:${product.id} | ${product.name}`,
                SKU: product.sku,
                Quantity: ''
              });
            }
          }

          console.log(rows);

        } catch (error) {
          console.error(error);
          this.snack(error.msg || error, "error");
        } finally {
          this.loader = false;
        }
      }
    }
  }
</script>
