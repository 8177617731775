<template>
  <div style="width: 100%;">
    <v-container v-if="!loader">
      <span class="d-flex flex-row align-center">
        <v-btn @click="$router.go(-1)" text><v-icon>mdi-arrow-left</v-icon></v-btn>
        <h1>Discounts</h1>
      </span>
      <v-card outlined>
        <v-card-text>
          <v-data-table
              :headers="headers"
              :items="items"
              :items-per-page="-1"
              class="elevation-0"
              @click:row="rowClick"
          >
            <template v-slot:item.status="{ item }">
              <v-chip :color="utils.getStatusColor(item.status)">{{utils.parseStatus(item.status)}}</v-chip>
            </template>
            <template v-slot:item.createdAt="{ item }">
              <span>{{formatDate(item.createdAt, "long") }}</span>
            </template>
            <template v-slot:item.csr="{ item }">
              <span>{{lookupUsername(item.createdBy)}}</span>
            </template>
            <template v-slot:item.subtotal="{ item }">
              <p>{{utils.formatCurrency(item.subtotal)}}</p>
            </template>
            <template v-slot:item.discountAmount="{ item }">
              <p>{{utils.formatCurrency(item.discountAmount)}}</p>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios';
  import {mapGetters} from "vuex";
  import utils from "./../../plugins/helpers"
  export default {
    data () {
      return {
        utils: utils,
        deleteDialog: false,
        deleteConfirmed: true,
        loader: true,
        snackObj: {
          state: false,
          color: '',
          text: ''
        },
        headers: [
          {text: 'ID', align: 'start', value: 'id'},
          {text: 'Status', value: 'status'},
          {text: 'Created At', value: 'createdAt'},
          {text: 'CSR', value: 'csr'},
          {text: 'Subtotal', value: 'subtotal'},
          {text: 'Discount Issued', value: 'discountAmount'},
          {text: 'Discount %', value: 'discountPercentage'},
        ],
        items: []
      }
    },
    async mounted(){
        await this.getAllData();
    },
    computed: {
      ...mapGetters(['getEndpoint', "isAllowed", 'lookupUsername']),
    },
    methods: {
      rowClick(row){
        this.$router.push({ path: `/orders/view/${row.id}`})
      },
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      formatDate(d, type="short"){
        if(!d) return "None";
        if(type=="short")
          return (new Date(d)).toLocaleDateString('en-GB')
        if(type=="long")
          return (new Date(d)).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
      },
      formatPrice(value){
        if(value===null) return "";
        let formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        });
        return formatter.format(parseFloat(value));
      },
      async getAllData(){
        try {
          let res = await axios.get(`${this.getEndpoint}/api/reporting/discounts`)
          if(res.data.error) throw res.data.error
          this.items = res.data.data.filter(y=>y.OrderLineItems.length>0&&((y.discountAmount/y.OrderLineItems.reduce((acc,z)=>acc+z.quantity*z.unitPrice,0))*100).toFixed(3)>10)
          this.items.map(x=>x.subtotal = x.OrderLineItems.reduce((acc,y)=>acc+y.quantity*y.unitPrice,0))
          this.items.map(x=>x.discountPercentage = ((x.discountAmount/x.OrderLineItems.reduce((acc,y)=>acc+y.quantity*y.unitPrice,0))*100).toFixed(3))
        } catch (error) {
          console.error(error);
          this.snack(error.msg || error, "error");
        } finally {
          this.loader = false;
        }
      }
    }
  }
</script>
